exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-about-us-main-js": () => import("./../../../src/pages/about-us/aboutUsMain.js" /* webpackChunkName: "component---src-pages-about-us-about-us-main-js" */),
  "component---src-pages-footer-footer-contact-details-js": () => import("./../../../src/pages/footer/footerContactDetails.js" /* webpackChunkName: "component---src-pages-footer-footer-contact-details-js" */),
  "component---src-pages-footer-footer-form-js": () => import("./../../../src/pages/footer/footerForm.js" /* webpackChunkName: "component---src-pages-footer-footer-form-js" */),
  "component---src-pages-footer-footer-main-js": () => import("./../../../src/pages/footer/footerMain.js" /* webpackChunkName: "component---src-pages-footer-footer-main-js" */),
  "component---src-pages-footer-footer-social-js": () => import("./../../../src/pages/footer/footerSocial.js" /* webpackChunkName: "component---src-pages-footer-footer-social-js" */),
  "component---src-pages-footer-footer-title-js": () => import("./../../../src/pages/footer/footerTitle.js" /* webpackChunkName: "component---src-pages-footer-footer-title-js" */),
  "component---src-pages-history-history-main-js": () => import("./../../../src/pages/history/historyMain.js" /* webpackChunkName: "component---src-pages-history-history-main-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-landing-main-js": () => import("./../../../src/pages/landing/landingMain.js" /* webpackChunkName: "component---src-pages-landing-landing-main-js" */),
  "component---src-pages-landing-landing-slide-js": () => import("./../../../src/pages/landing/landingSlide.js" /* webpackChunkName: "component---src-pages-landing-landing-slide-js" */),
  "component---src-pages-navbar-contact-details-js": () => import("./../../../src/pages/navbar/contactDetails.js" /* webpackChunkName: "component---src-pages-navbar-contact-details-js" */),
  "component---src-pages-navbar-navbar-main-js": () => import("./../../../src/pages/navbar/navbarMain.js" /* webpackChunkName: "component---src-pages-navbar-navbar-main-js" */),
  "component---src-pages-our-work-our-work-carousel-js": () => import("./../../../src/pages/our-work/ourWorkCarousel.js" /* webpackChunkName: "component---src-pages-our-work-our-work-carousel-js" */),
  "component---src-pages-our-work-our-work-main-js": () => import("./../../../src/pages/our-work/ourWorkMain.js" /* webpackChunkName: "component---src-pages-our-work-our-work-main-js" */)
}

